import { useEffect, useState, useRef } from 'react';

import { If, toClassName, createComponent, IntrinsicProps } from '@/common/util/templateHelpers';
import Flex from '@/common/components/Flex';
import Spacer, { SpacerImage } from '@/common/components/Spacer';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useSupportsTouch } from '@/common/hooks/supportsTouch';
import Transition from './Transition';
import { EventListener, EventMap } from '@/util/eventListener';

enum GamePreviewState {
  WAITING = 'waiting',
  INTERSTITIAL = 'interstitial',
  LOADING = 'loading',
  HIDDEN = 'hidden',
  ERROR = 'error'
}

type ClickPlayHandler = (loadGame: () => void) => void

interface GamePlayerProps extends IntrinsicProps {
  previewImage: string
  gameSrc: string
  autoPlay?: boolean
  boundingBox?: DOMRect
  fullScreen?: boolean
  width?: number
  height?: number
  isFlash?: boolean
  onClickPlay: ClickPlayHandler
  onClickExitFullScreen?: () => void
  onClickEnterFullScreen?: () => void
  supportsMobile?: {
    portrait?: boolean
    landscape?: boolean
  }
  spacer?: SpacerImage
  fluid?: boolean
  gameOverlayTransition?: string
  gameOverlayTransitionTimeout?: number
  bottomPromoTransition?: string
  bottomPromoTransitionTimeout?: number
  hideFullScreenCloseButton?: boolean
  previewTransition?: any
  resetOnExitFullScreen?: boolean | 'mobile'
}

const gamePlayerStates = [
  'fluid'
];


export type MobileEvents = EventMap & {
  'play': (event: {}) => void
  'exitFullScreen': (event: {}) => void
  'fullScreenChange': (event: { fullScreen: boolean }) => void
}

class MobileHeaderEventListener extends EventListener<MobileEvents> {}
export const gamePlayerEventListener = new MobileHeaderEventListener();

export default createComponent<GamePlayerProps>('GamePlayer', { classStates: gamePlayerStates }, function GamePlayer ({ className, mergeClassNames, style, slots }, props) {
  // Component state
  const [ showGame, setShowGame ] = useState(false);
  const [ previewState, setPreviewState ] = useState(GamePreviewState.WAITING);
  const el = useRef<HTMLDivElement>(null);
  const objectEl = useRef<HTMLObjectElement>(null);
  const [ fullScreen, setFullScreen ] = useState(props.fullScreen);
  const router = useRouter();
  const supportsTouch = useSupportsTouch();

  // If navigation occurs make sure we're no longer in fullscreen mode
  useEffect(() => {
    if (router.asPath.includes('play=instant')) return;

    const onRouteChange = () => setFullScreen(false);
    router.events.on('routeChangeStart', onRouteChange);

    return () => {
      router.events.off('routeChangeStart', onRouteChange);
    };
  }, [ router.events ]);

  // Preview/interstial logic
  const previewStyles = {
    backgroundImage: `url(${props.previewImage})`,
    zIndex: -2,

  };

  // Watch preview state
  const initialRender = useRef(true);
  const onClickPlay = props.onClickPlay;
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (previewState === GamePreviewState.INTERSTITIAL) {
      if (onClickPlay) {
        onClickPlay(() => {
          loadGame();
        });
      }
    }
  }, [ previewState, onClickPlay ]);

  // Load game
  const loadGame = () => {
    setPreviewState(GamePreviewState.LOADING);
    setShowGame(true);
  };

  {
    const { isFlash, gameSrc } = props;
    useEffect(() => {
      if (!objectEl.current) return;
      
      (async () => {
        const el = objectEl.current;
        if (!isFlash) el.setAttribute('data', gameSrc);
      })();
    }, [ showGame, isFlash, gameSrc ]);
  }

  {
    const { autoPlay } = props;
    useEffect(() => {
      if (autoPlay) loadGame();
    }, [ autoPlay ]);

    useEffect(() => {
      const playListener = gamePlayerEventListener.on('play', () => {
        loadGame();
      });

      const exitFullScreenListener = gamePlayerEventListener.on('exitFullScreen', () => {
        setFullScreen(false);
      });
      
      return () => {
        gamePlayerEventListener.off('play', playListener);
        gamePlayerEventListener.off('exitFullScreen', exitFullScreenListener);
      };
    }, []);
  }

  // If unmounting component, make sure we remove ruffle
  // next/script doesn't unmount, we have to do it
  useEffect(() => {
    return () => {
      document.querySelector('#ruffle-config')?.remove();
      document.querySelector('#ruffle-load')?.remove();
    };
  }, []);

  // If the game changes, make sure we reset the player state
  useEffect(() => {
    setShowGame(false);
    setPreviewState(GamePreviewState.WAITING);
  }, [ props.gameSrc ]);

  // Handlers & methods
  const clickPlay = () => {
    if (props.onClickPlay) {
      setPreviewState(GamePreviewState.INTERSTITIAL);
    } else {
      setShowGame(true);
    }
  };

  const gameLoaded = (evt) => {
    setPreviewState(GamePreviewState.HIDDEN);
  };

  // Fullscreen logic
  const boundingBox = {
    top: props.boundingBox?.top || 0,
    left: props.boundingBox?.left || 0,
    right: props.boundingBox?.right || 0,
    bottom: props.boundingBox?.bottom || 0
  };

  // Handler for the parent to request a change to the fullScreen state
  useEffect(() => {
    setFullScreen(props.fullScreen);
  }, [ props.fullScreen ]);

  // Handler for built-in browser fullScreen API events
  useEffect(() => {
    const handler = () => {
      const doc = document as any;

      const fullScreenElement = 
        doc.fullscreenElement ||
        doc.mozFullScreenElement ||
        doc.webkitFullscreenElement ||
        doc.webkitCurrentFullScreenElement ||
        doc.msFullscreenElement;

      setFullScreen(!!fullScreenElement);
    };

    document.documentElement.addEventListener('fullscreenchange', handler);
    document.documentElement.addEventListener('webkitfullscreenchange', handler);

    return () => {
      document.documentElement.removeEventListener('fullscreenchange', handler);
      document.documentElement.removeEventListener('webkitfullscreenchange', handler);
    };
  }, []);

  // Handler for fullScreen state
  {
    const { onClickEnterFullScreen, onClickExitFullScreen } = props;
    useEffect(() => {
      gamePlayerEventListener.trigger('fullScreenChange', { fullScreen });

      if (fullScreen) {
        if (onClickEnterFullScreen) onClickEnterFullScreen();
        document.body.classList.add('--fullScreen');
      } else {
        if (onClickExitFullScreen) onClickExitFullScreen();
        document.body.classList.remove('--fullScreen');

        if (
          (props.resetOnExitFullScreen === 'mobile' && supportsTouch) || 
          (props.resetOnExitFullScreen === true)
        ) {
          setShowGame(false);
          setPreviewState(GamePreviewState.WAITING);
        } 
      }
  
      // Bail out if either:
      // 1. This is a mobile device since many don't support the fullScreen API
      // 2. The game object element doesn't exist yet since it needs to be ready
      //    in order to use the fullScreen API
      if (supportsTouch || !el.current) return;
  
      // Otherwise, invoke the browser's fullScreen API
      const doc = document as any;
  
      const fullScreenElement = 
        doc.fullscreenElement ||
        doc.mozFullScreenElement ||
        doc.webkitFullscreenElement ||
        doc.webkitCurrentFullScreenElement ||
        doc.msFullscreenElement;
  
      if (fullScreen && !fullScreenElement) {
        const el = document.documentElement as any;
  
        const requestFullscreen = 
          el.requestFullscreen || 
          el.webkitRequestFullScreen ||
          el.webkitRequestFullscreen ||
          el.mozRequestFullScreen || 
          el.msRequestFullscreen;
  
        requestFullscreen.call(el, { navigationUI: 'hide' });
      } 
      
      if(!fullScreen && fullScreenElement) {
        const exitFullscreen = 
          doc.exitFullscreen || 
          doc.mozCancelFullScreen || 
          doc.webkitExitFullscreen || 
          doc.msExitFullscreen;
        
        exitFullscreen.call(doc);
      }
    }, [ fullScreen ]);
  }

  // Game object
  const gameStyles = {
    width: props.width,
    height: props.height
  };

  // Styles
  style = {
    ...style,
    ...(fullScreen ? boundingBox : gameStyles)
  };

  // Control fullScreen class internally
  className = mergeClassNames(
    toClassName('GamePlayer', {
      fullScreen,
      staticSize: !!props.width && !!props.height,
      isPlaying: showGame,
    }), 
    className
  );

  const [ orientation, setOrientation ] = useState<'landscape' | 'portrait' | null>(null);

  useEffect(() => {
    const orientationHandler = () => {
      const { innerWidth, innerHeight } = window;
      const isLandscape = innerWidth > innerHeight;

      setOrientation(isLandscape ? 'landscape' : 'portrait');
    };

    window.addEventListener('resize', orientationHandler);
    window.addEventListener('orientationchange', orientationHandler);

    orientationHandler();

    return () => {
      window.removeEventListener('resize', orientationHandler);
      window.removeEventListener('orientationchange', orientationHandler);
    };
  }, []);

  return (
    <div className={className} style={style}>
      {
        If((showGame && props.isFlash), () => (
          <>
            <Script 
              id="ruffle-load" 
              src="/scripts/ruffle/2021-04-04/ruffle.js" 
              strategy="lazyOnload"
              onLoad={gameLoaded}
            />
            <Script
              id="ruffle-config" 
              dangerouslySetInnerHTML={{
                __html: `
                  window.RufflePlayer = window.RufflePlayer || {};
                  window.RufflePlayer.config = {
                    // Options affecting the whole page
                    "publicPath": undefined,
                    "polyfills": true,
            
                    // Options affecting files only
                    "autoplay": "auto",
                    "unmuteOverlay": "visible",
                    "backgroundColor": null,
                    "letterbox": "on",
                    "warnOnUnsupportedContent": true,
                    "contextMenu": true,
                    "upgradeToHttps": true,
                    "maxExecutionDuration": {"secs": 15, "nanos": 0},
                  };
                `
              }}
              strategy="afterInteractive"
            />
          </>
        )).EndIf()
      }
      
      <Transition 
        visible={previewState !== GamePreviewState.HIDDEN} 
        leave={props.previewTransition} 
        className='GamePlayer__Overlay'
        style={{    zIndex: -2,
        }}
      >
        <div className={`GamePlayer__PreviewOverlay ${!props.supportsMobile ? 'GamePlayer__PreviewOverlay--remove' : ''}`} style={previewStyles} />

        {
          If(previewState === GamePreviewState.WAITING, () => (

            <Flex key='playButton' alignCenter justifyCenter className='GamePlayer__Overlay'  style={{ zIndex: -2 }}>

              <div onClick={clickPlay}>
                {slots.playButton}
              </div>
            </Flex>
          ))
            .ElseIf(previewState === GamePreviewState.INTERSTITIAL, () => (
              <Flex key='interstitial' alignCenter justifyCenter className='GamePlayer__Overlay'  style={{ zIndex: -2 }}>
                {slots.interstitial}
              </Flex>
            ))
            .ElseIf(previewState === GamePreviewState.LOADING, () => (
              <Flex key='loader' alignCenter justifyCenter className='GamePlayer__Overlay '  style={{ zIndex: -2 }}>
                <div className='GamePlayer__Loader' />
              </Flex>
            ))
            .ElseIf(previewState === GamePreviewState.ERROR, () => (
              <Flex key='error' alignCenter justifyCenter className='GamePlayer__Incompatible'>
                {slots.error ? slots.error : 'An error occurred while loading this game. Please try again later.'}
              </Flex>
            ))
            .EndIf()
        }
        <Transition 
          visible={previewState !== GamePreviewState.HIDDEN && previewState !== GamePreviewState.ERROR} 
          leave={{ translateY: '150%', opacity: 0 }} 
          className={toClassName('GamePlayer__Anchor', '&--bottom')} 
          style={{ width: '100%' }}
        >
          <Flex justifyCenter wide>
            <div>
              {slots.bottomPromo}
            </div>
          </Flex>
        </Transition>
      </Transition>

      <div className='GamePlayer__Game' style={gameStyles}>
        {
          If(showGame || props.autoPlay, () => (
            <>
              {
                If(props.isFlash, () => (
                  <object 
                    ref={objectEl}
                    width={props.width ? props.width : '100%'} 
                    height={props.height ? props.height : '100%'}
                  >
                    <param value={props.gameSrc} />
                    <embed src={props.gameSrc}  />
                  </object>
                )).Else(() => (
                  <object 
                    ref={objectEl} 
                    type="text/html"
                    width={props.width ? props.width : '100%'} 
                    height={props.height ? props.height : '100%'}
                    onLoad={gameLoaded}
                  />
                )).EndIf()
              }
            </>
          ))
            .EndIf()
        }
        {
          If(props.spacer && !props.width, () => (
            <Spacer wide spacer={props.spacer} />
          ))
            .EndIf()
        }
      </div>
      {
        If(!props.supportsMobile, () => (
          <Flex alignCenter justifyCenter className={toClassName('GamePlayer__Incompatible', '&--mobile')}>
            {slots.mobileUnsupported ? slots.mobileUnsupported : 'This game does not support mobile devices.'}
          </Flex>
        ))
          .Else(() => (
            <>
              {
                If((showGame || props.autoPlay) && orientation === 'landscape' && !props.supportsMobile.landscape, () => (
                  <Flex alignCenter justifyCenter className={toClassName('GamePlayer__Incompatible', '&--landscape')}>
                    {slots.landscapeUnsupported ? slots.landscapeUnsupported : 'Rotate the device into portrait mode to play this game.'}
                  </Flex>
                ))
                  .EndIf()
              }
              {
                If((showGame || props.autoPlay) && orientation === 'portrait' && !props.supportsMobile.portrait, () => (
                  <Flex alignCenter justifyCenter className={toClassName('GamePlayer__Incompatible', '&--portrait')}>
                    {slots.portraitUnsupported ? slots.portraitUnsupported : 'Rotate the device into landscape mode to play this game.'}
                  </Flex>
                ))
                  .EndIf()
              }
            </>
          ))
          .EndIf()
      }
      {
        If(fullScreen && (previewState === GamePreviewState.WAITING || showGame), () => (
          <div className={toClassName('GamePlayer__Anchor', '&--top', '&--right')} style={{ zIndex: 4 }}>
            <Flex 
              alignCenter 
              justifyCenter
            >
              <span className='GamePlayer__CloseButton' onClick={() => setFullScreen(false)}>&times;</span>
            </Flex>
          </div>
        ))
          .EndIf()
      }
    </div>
  );
});
